import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button, { ButtonProps } from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

interface TnButtonProps extends ButtonProps {
  value: string;
  widthClass?: any;
  classes?: any;
  variant: 'contained' | 'outlined' | 'text';
  color: 'primary' | 'secondary';
  dataTestId?: string;
  link?: any;
  type?: any;
}

class TnButton extends Component<TnButtonProps, any> {
  render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    const { value, classes, dataTestId, link } = this.props;

    const linkClass = {
      textDecoration: 'none',
      color: 'inherit',
    };

    const buttonComponent = (
      <Button
        data-testid={dataTestId == null ? value + '_Button' : dataTestId + '_Button'}
        classes={{ root: classes.root }}
        {...this.props}
      >
        {value}
      </Button>
    );

    return link !== undefined ? (
      <Link to={link} style={linkClass}>
        {buttonComponent}
      </Link>
    ) : (
      buttonComponent
    );
  }
}

export default withStyles(() => ({
  root: {
    height: '40px',
    textTransform: 'capitalize',
  },
}))(TnButton);
