import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { Theme, withStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { StyledComponentProps } from '@material-ui/styles';
import classNames from 'classnames';
import React from 'react';

export type SnackBarVariant = keyof typeof variantIcon;

interface SnackBarContentProps {
  classes: any;
  className?: string;
  message: string;
  onClose: () => void;
  variant: SnackBarVariant;
  open: boolean;
}

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles1 = (theme: Theme) => ({
  success: {
    borderLeft: '7px solid #43a047',
  },
  error: {
    borderLeft: '7px solid #d32f2f',
  },
  info: {
    borderLeft: '7px solid #1976d2',
  },
  warning: {
    borderLeft: '7px solid #ffa000',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

function MySnackbarContent(props: SnackBarContentProps) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

interface TnSnackBarProps {
  handleClose: () => void;
  open: boolean;
  classes: StyledComponentProps<any>['classes'];
  variant: SnackBarVariant;
  message: string;
}

interface TnSnackBarState {
  open: boolean;
}

class TnSnackbar extends React.Component<TnSnackBarProps, TnSnackBarState> {
  constructor(props: TnSnackBarProps) {
    super(props);
    this.state = {
      open: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(event?: React.SyntheticEvent<any, Event>, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false });
    this.props.handleClose && this.props.handleClose();
  }

  componentWillReceiveProps(props: TnSnackBarProps) {
    this.setState({ open: props.open });
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={this.state.open}
          autoHideDuration={8000}
          onClose={this.handleClose}
          className={classes?.root}
        >
          <MySnackbarContentWrapper
            classes={classes}
            open={this.state.open}
            onClose={this.handleClose}
            variant={this.props.variant}
            message={this.props.message}
            data-testid={'Snackbar_Close'}
          />
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(() => ({
  root: {
    zIndex: 100000,
  },
}))(TnSnackbar);
