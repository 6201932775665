export function getSuffixOfImageId(auth0Id: string) {
  let auth0IdSplit = auth0Id.split('|');
  return auth0IdSplit.length == 2 ? auth0IdSplit[1] : '';
}

export function getSuffixOfProfilePicURL(profilePicURL: string) {
  if (!profilePicURL) {
    return '';
  }
  let suffixId = profilePicURL.split('/');
  return suffixId.length > 0 ? suffixId[suffixId.length - 1] : '';
}
