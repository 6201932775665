import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';

function CircularLoader(props: any) {
  const { classes, inline } = props;
  return (
    <CircularProgress
      size={inline && 22}
      className={classNames({
        [classes.root]: inline === undefined || inline === false,
        [classes.inline]: inline === true,
      })}
      disableShrink
    />
  );
}

const Loader = withStyles((theme) => ({
  root: {
    position: 'fixed',
    display: 'block',
    zIndex: 100000,
    left: '50%',
    top: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'grey',
  },
  inline: {
    justifyContent: 'center',
    alignItems: 'center',
    color: 'grey',
    width: '15%',
    height: 22,
  },
}))(CircularLoader);

export default Loader;
