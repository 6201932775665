import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, { Component, lazy, Suspense } from 'react';
import { Query } from 'react-apollo';
import { Route, Router, Switch } from 'react-router-dom';
import './App.css';
import { ClientData } from './index';
import Loader from './_components/Loader';
import { history } from './_helpers/history';
import { GET_LOGGEDIN } from './_services/client-quieries/clienttypes';
import Grid from '@material-ui/core/Grid';
import ClientDashboard from './_components/ClientDashboard';
import Callback from './views/Callback';
import auth from './_services/auth/Auth';
import { PrivateRoute } from './_components/routes/PrivateRoute';
import NavigationBar from './_components/navbar/NavigationBar';
import { hasPermission } from './_components/permissions/CheckPermission';
import { ThemeContext } from './store/ThemeContext';
import TnSnackBar from './_components/snackbar/TnSnackBar';
import IdleTimer from 'react-idle-timer';
import InactivityModal from './_components/InactivityModal';
import * as Sentry from '@sentry/browser';
import EditJob from '@components/jobs/EditJob';

const { BroadcastChannel } = require('broadcast-channel');
const channel = new BroadcastChannel('talentnow-marketplace-' + process.env.ENV || 'prod');

var idleTimer: any = null;

function setTimeoutModalVisibility() {
  localStorage.setItem('hideTimeoutModal', 'false');
}

channel.onmessage = (msg: string) => {
  if (msg === 'Stop timer') {
    idleTimer && idleTimer.pause();
  } else if (msg === 'Detached Logout') {
    setTimeoutModalVisibility();
    history.push({ pathname: '/login', state: { timedOut: 'true' } });
  }
};

interface IProps {}

interface IState {
  [key: string]: any;
}

const Jobs = lazy(() => import('../src/_components/jobs/Jobs'));
const CreateJob = lazy(() => import('../src/_components/jobs/CreateJob'));
const CandidateForm = lazy(() => import('../src/views/candidate/CandidateForm'));
const Marketplace = lazy(() => import('../src/views/marketplace/Marketplace'));
const CandidateView = lazy(() => import('./views/candidate-details/CandidateMainPage'));
const VendorRedirect = lazy(() => import('./views/VendorRedirect'));
const PrivacyPolicy = lazy(() => import('./views/login/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./views/login/TermsOfUse'));
const LoginScreen = lazy(() => import('./views/login/LoginScreen'));
const SystemSettings = lazy(() => import('./views/adminSettings/SystemSettings'));
const VendorDetails = lazy(() => import('./views/vendor/vendor-details/VendorDetails'));
const VendorListContainer = lazy(() => import('./views/vendor/VendorListContainer'));
const VendorOnboarding = lazy(() => import('./views/vendor/VendorOnboarding'));
const AddVendorDetails = lazy(() => import('./views/add-vendor-details/AddVendorDetailsMainPage'));
const WorkspaceRedirect = lazy(() => import('./views/WorkspaceRedirect'));

class App extends Component<IProps, IState> {
  static contextType = ThemeContext;

  constructor(props: IProps) {
    super(props);
    this.state = {
      hasCreateJob: false,
      hasSubmitCandidate: false,
      hasVendorAccess: false,
      hasVendorOnboardAccess: false,
      hasVendorView: false,
      hasAdminSettings: false,
      hasSuperAdminSettings: false,
      time: parseInt(process.env.UI_SESSION_TIMEOUT || '1800000'),
      open: false,
      remainingTime: 0,
      heading: 'Inactivity Timer',
      showMessage: false,
      message: '',
      variant: 'info',
    };
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.onIdle = this.onIdle.bind(this);
    this.stayHere = this.stayHere.bind(this);
    this.logout = this.logout.bind(this);
    this.onAction = this.onAction.bind(this);
    this.handleOnClose = this.handleOnClose.bind(this);
    this.setPermissions();
  }

  componentDidMount() {
    const sessionExpiry = parseInt(localStorage.getItem('st') || '0');
    if (new Date().getTime() > sessionExpiry && sessionExpiry != 0) {
      history.push('/login');
    }
    localStorage.removeItem('st');
  }

  componentDidCatch(err: any, info: any) {
    Sentry.captureException(err);
  }

  handleVisibilityChange() {
    if (document.visibilityState == 'visible') {
      if (!(window.location.href.indexOf('login') > -1)) {
        channel.postMessage('Stop timer');
      }
      idleTimer && idleTimer.resume();
      const sessionExpiry = parseInt(localStorage.getItem('st') || '0');
      if (new Date().getTime() > sessionExpiry && sessionExpiry != 0) {
        setTimeoutModalVisibility();
        history.push({ pathname: '/login', state: { timedOut: 'true' } });
      }
      localStorage.removeItem('st');
    }
  }

  onIdle(e: any) {
    if (this.state.time == parseInt(process.env.UI_SESSION_TIMEOUT || '1800000')) {
      this.openModal();
    } else {
      channel.postMessage('Detached Logout');
      setTimeoutModalVisibility();
      history.push({ pathname: '/login', state: { timedOut: 'true' } });
      this.setState({
        showMessage: true,
        message: 'Your session has been timed out',
        variant: 'info',
      });
      setTimeout(this.closeSnackBar, 3000);
    }

    this.setState(
      {
        time: parseInt(process.env.UI_TIMEOUT_BUFFER || '60000'),
        remainingTime: idleTimer.getRemainingTime(),
      },
      () => {
        idleTimer.reset();
      },
    );
  }

  onAction() {
    localStorage.removeItem('st');
  }

  openModal = () => {
    this.setState({ open: true });
  };

  handleOnClose = () => {
    this.setState({ open: false });
  };

  closeSnackBar = () => {
    this.setState({ showMessage: false });
  };

  stayHere = () => {
    if (this.state.open) {
      this.handleOnClose();

      auth.silentAuth();
      this.setState({ time: parseInt(process.env.UI_SESSION_TIMEOUT || '1800000') }, () => {
        idleTimer.reset();
        this.setState({
          showMessage: true,
          message: 'Thank you for confirming',
          variant: 'info',
        });
        setTimeout(this.closeSnackBar, 3000);
      });
    }
  };

  logout = () => {
    this.handleOnClose();
    history.push('/login');
  };

  handleClose = () => {
    this.setState({
      showMessage: false,
    });
  };

  setPermissions = async () => {
    this.setState({
      hasCreateJob: await hasPermission('job:create'),
      hasSubmitCandidate: await hasPermission('candidate:submit'),
      hasAdminSettings: await hasPermission('system_settings'),
      hasSuperAdminSettings: await hasPermission('super_admin_settings'),
      hasVendorAccess: await hasPermission('vendor:access'),
      hasVendorOnboardAccess: await hasPermission('vendor:onboard'),
      hasVendorView: await hasPermission('vendor:view'),
    });
  };

  render():
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {
    return (
      <MuiThemeProvider theme={this.context.currentTheme.theme}>
        <Router history={history}>
          <Suspense fallback={<Loader />}>
            <Grid container style={{ minHeight: '100vh' }}>
              <Grid item={true} xs={12}>
                <Query<ClientData> query={GET_LOGGEDIN}>
                  {({ data }) => {
                    if (data && data.isLoggedIn) {
                      //@ts-ignore
                      // window.showChatBox(data.loggedInUserFirstname + " " + data.loggedInUserLastname);
                      document.addEventListener('visibilitychange', this.handleVisibilityChange, false);

                      return (
                        <React.Fragment>
                          <NavigationBar
                            role={data.loggedInUserRole}
                            userName={data.loggedInUserFirstname + ' ' + data.loggedInUserLastname}
                            imageId={data.loggedInUserAuth0Id}
                          />
                          <IdleTimer
                            ref={(ref) => {
                              idleTimer = ref;
                            }}
                            element={document}
                            onIdle={this.onIdle}
                            debounce={250}
                            onAction={this.onAction}
                            timeout={this.state.time}
                          />
                          <InactivityModal
                            open={this.state.open}
                            stayHere={this.stayHere}
                            logout={this.logout}
                            heading={this.state.heading}
                            handleOnClose={this.handleOnClose}
                          />
                        </React.Fragment>
                      );
                    } else {
                      //@ts-ignoregit push --set-upstream origin VMS-5369
                      //  window.hideChatBox()
                      return null;
                    }
                  }}
                </Query>
                <Switch>
                  <PrivateRoute exact path="/" render={(props: any) => <ClientDashboard {...props} />} />
                  <PrivateRoute exact path="/jobs" render={() => <Jobs hasCreateJob={this.state.hasCreateJob} />} />
                  {this.state.hasCreateJob && (
                    <PrivateRoute exact path="/createJob" render={(props: any) => <CreateJob {...props} />} />
                  )}
                  {this.state.hasSubmitCandidate && (
                    <PrivateRoute
                      exact
                      path="/submitCandidate"
                      render={(props: any) => <CandidateForm {...props} mode={'create'} />}
                    />
                  )}
                  {this.state.hasSubmitCandidate && [
                    <PrivateRoute
                      key="0"
                      exact
                      path={'/editCandidate/:id'}
                      render={(props: any) => <CandidateForm {...props} mode={'edit'} />}
                    />,
                  ]}
                  <PrivateRoute exact path="/editJob/:id" render={(props: any) => <EditJob {...props} />} />
                  <PrivateRoute exact path="/marketplace" render={(props: any) => <Marketplace {...props} />} />
                  <PrivateRoute exact path="/candidates" render={(props: any) => <Marketplace {...props} />} />
                  <PrivateRoute
                    exact
                    path={'/candidates/:id/profile'}
                    render={(props: any) => <CandidateView {...props} tabItem={'profile'} />}
                  />
                  <PrivateRoute
                    exact
                    path={'/candidates/:id/activities'}
                    render={(props: any) => <CandidateView {...props} tabItem={'activities'} />}
                  />
                  <PrivateRoute
                    exact
                    path={'/candidates/:id/currentOffers'}
                    render={(props: any) => <CandidateView {...props} tabItem={'current-offers'} />}
                  />
                  {this.state.hasAdminSettings && [
                    <PrivateRoute
                      key="0"
                      exact
                      path={'/systemSettings/user'}
                      render={(props: any) => <SystemSettings {...props} tabItem={'user'} />}
                    />,
                    <PrivateRoute
                      key="1"
                      exact
                      path={'/systemSettings/candidates'}
                      render={(props: any) => <SystemSettings {...props} tabItem={'candidates'} />}
                    />,
                  ]}
                  <Route exact path="/callback" render={(props: any) => <Callback {...props} />} />
                  <Route exact path="/vendorRedirect" render={(props: any) => <VendorRedirect {...props} />} />
                  <Route
                    exact
                    path={['/login', '/change-password']}
                    render={(props: any) => <LoginScreen {...props} />}
                  />
                  <Route exact path={'/terms-of-use'} render={(props: any) => <TermsOfUse {...props} />} />
                  <Route exact path={'/privacy-policy'} render={(props: any) => <PrivacyPolicy {...props} />} />
                  {process.env.MARKETPLACE_V2_ENABLED === 'true' && (
                    <Route exact path={'/vendorOnboarding'} render={(props: any) => <VendorOnboarding {...props} />} />
                  )}
                  <Route
                    exact
                    path={'/addVendorDetails/:vendorId/:verificationCode'}
                    render={(props: any) => <AddVendorDetails {...props} />}
                  />
                  {this.state.hasVendorAccess && (
                    <PrivateRoute exact path={'/vendors'} render={(props: any) => <VendorListContainer {...props} />} />
                  )}
                  {this.state.hasVendorAccess && [
                    <PrivateRoute
                      key="1"
                      exact
                      path={'/vendorDetails/:id/profile'}
                      render={(props: any) => <VendorDetails {...props} tab={'profile'} />}
                    />,
                    <PrivateRoute
                      key="5"
                      exact
                      path={'/vendorDetails/:id/documents'}
                      render={(props: any) => <VendorDetails {...props} tab={'documents'} />}
                    />,
                    <PrivateRoute
                      key="6"
                      exact
                      path={'/vendorDetails/:id/hiring_companies'}
                      render={(props: any) => <VendorDetails {...props} tab={'hiring_companies'} />}
                    />,
                  ]}
                  {this.state.hasVendorView && [
                    <PrivateRoute
                      key="7"
                      exact
                      path={'/vendorProfile/profile'}
                      render={(props: any) => <VendorDetails {...props} tab={'profile'} />}
                    />,
                    <PrivateRoute
                      key="11"
                      exact
                      path={'/vendorProfile/documents'}
                      render={(props: any) => <VendorDetails {...props} tab={'documents'} />}
                    />,
                  ]}
                  <Route exact path={'/workspaceRedirect'} render={(props: any) => <WorkspaceRedirect {...props} />} />
                </Switch>
              </Grid>
              <TnSnackBar
                open={this.state.showMessage}
                message={this.state.message}
                variant={this.state.variant}
                handleClose={this.handleClose}
              />
            </Grid>
          </Suspense>
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default App;
