import React, { useState } from 'react';
import TnModal from '../_components/tn-modal/TnModal';
import { Grid, withStyles } from '@material-ui/core';
import TnTypography from '../_components/tn-typography/TnTypography';
import TnButton from '../_components/tn-button/TnButton';

interface Props {
  classes: any;
  open: boolean;
  stayHere: any;
  logout: any;
  heading: string;
  handleOnClose: any;
}

function InactivityModal(props: Props) {
  const { classes, open, stayHere, logout, heading } = props;

  return (
    <TnModal open={open} handleClose={() => stayHere()} headerLabel={heading} customClass={classes.customModalClass}>
      <Grid container justify={'center'} className={classes.gridContainer}>
        <Grid item xs={12}>
          <Grid container justify={'center'} direction={'column'} alignItems={'center'}>
            <Grid item>
              <Grid container justify={'center'} alignItems={'center'} className={classes.selected}>
                <Grid item>
                  <TnTypography variant={'body'}>
                    Are you still here? Your session will get timed out soon.
                  </TnTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction={'row'} spacing={4}>
                <Grid item>
                  <TnButton value="Stay here" color="primary" variant="contained" onClick={() => stayHere()} />
                </Grid>
                <Grid item>
                  <TnButton value="Logout" color="primary" variant="contained" onClick={() => logout()} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TnModal>
  );
}

export default withStyles((theme: any) => ({
  selected: {
    height: 55,
  },
  customModalClass: {
    position: 'relative',
    width: '33.62%',
    minHeight: '160px',
    outline: 'none',
    backgroundColor: 'white',
    borderRadius: 0,
    top: '98.46px',
    margin: '0 auto',
    [theme.breakpoints.down('1080')]: {
      width: '90%',
    },
  },
  apply: {
    margin: theme.spacing(2),
  },
}))(InactivityModal);
