import React, { createContext, useEffect, useState } from 'react';
import { defaultTheme, krogerTheme, talentNowTheme } from '../constants/Styles';

const ThemeContext = createContext<any>(null);

const ThemeProvider: React.FC = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState({
    label: 'Koncert',
    theme: defaultTheme,
  });

  useEffect(() => {
    changeTheme(localStorage.getItem('theme'));
  }, []);

  const changeTheme = (theme: string | null) => {
    switch (theme) {
      case 'Koncert':
        setCurrentTheme({
          label: 'Koncert',
          theme: defaultTheme,
        });
        localStorage.setItem('themePrimary', defaultTheme.palette.primary.main);
        break;
      case 'Talent Now':
        setCurrentTheme({
          label: 'Talent Now',
          theme: talentNowTheme,
        });
        localStorage.setItem('themePrimary', talentNowTheme.palette.primary.main);
        break;
      case 'Kroger':
        setCurrentTheme({
          label: 'Kroger',
          theme: krogerTheme,
        });
        localStorage.setItem('themePrimary', krogerTheme.palette.primary.main);
        break;
      default:
        setCurrentTheme({
          label: 'Kroger',
          theme: krogerTheme,
        });
        localStorage.setItem('themePrimary', krogerTheme.palette.primary.main);
        break;
    }
    localStorage.setItem('theme', theme ? theme : '');
  };

  return <ThemeContext.Provider value={{ currentTheme, changeTheme }}>{children}</ThemeContext.Provider>;
};

export { ThemeContext, ThemeProvider };
