import { client, getData } from '..';
import { GetUserByAuth0IdQuery } from '../_services/user/queries';
import { history } from './history';

export async function getUserByAuth0Id(auth0Id: string, role: string, redirectURL?: string) {
  client
    .query({
      query: GetUserByAuth0IdQuery,
      variables: { auth0_id: auth0Id, role: role },
    })
    .then((response: any) => {
      if (response.data && response.data.user && response.data.user.result && response.data.user.result.id) {
        if (response.data.user.result.is_active) {
          client.writeData({ data: getData() });
          // history.push('/')
          if (redirectURL) {
            window.location.href = redirectURL;
          } else window.location.href = process.env.API_URL ? process.env.API_URL : '';
        } else {
          history.push({
            pathname: '/login',
            state: {
              message: 'Your account is deactivated. Kindly contact Administrator',
            },
          });
        }
      }
    });
}
