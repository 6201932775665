import gql from 'graphql-tag';

export const GetUserByEmailQuery = gql`
  query GetUserByEmail($email_id: String!) {
    checkifUserExists(email_id: $email_id) {
      result {
        id
        roles {
          id
          name
        }
        auth0_id
      }
    }
  }
`;

export const GetUserByAuth0IdQuery = gql`
  query GetUserByAuth0Id($auth0_id: String!, $role: String) {
    user(auth0_id: $auth0_id, role: $role) {
      result {
        id
        name
        auth0_id
        email_id
        phone_number
        first_name
        last_name
        role {
          name
        }
        created_by
        permissions {
          name
        }
        is_active
        is_first_login
        forgot_pwd_check
      }
    }
  }
`;

export const ChangeUserPassword = gql`
  mutation changeUserPassword($input: ChangeUserPasswordInput) {
    changeUserPassword(input: $input) {
      success
    }
  }
`;

export const ContactUsMutation = gql`
  mutation contactUs($name: String, $email_id: String) {
    contactUs(name: $name, email_id: $email_id) {
      success
    }
  }
`;

export const IsEligibleForMarketplace = gql`
  query isEligibleForMarketplace {
    isEligibleForMarketplace
  }
`;

export const GetAllUserAccountsForAdmin = gql`
  query getAllUserAccountsForAdmin {
    getAllUserAccountsForAdmin {
      id
      first_name
      last_name
      email_id
      roles {
        id
        name
      }
      phone_number
      is_active
      created_on
      last_logged_in_on
      updated_on
      created_by_user {
        first_name
        last_name
      }
      updated_by_user {
        first_name
        last_name
      }
      auth0_id
      user_role_id
    }
  }
`;

export const GetAllRoles = gql`
  query getAllRoles {
    getAllRoles {
      id
      name
    }
  }
`;

export const AddUserAccountForAdmin = gql`
  mutation addUserAccountForAdmin($input: UserInputForAdmin) {
    addUserAccountForAdmin(input: $input) {
      message
      success
      result {
        id
        first_name
        last_name
      }
    }
  }
`;
export const EditUserAccountForAdmin = gql`
  mutation editUserAccountForAdmin($input: UserInputForAdmin) {
    editUserAccountForAdmin(input: $input) {
      message
      success
      result {
        id
        first_name
        last_name
      }
    }
  }
`;
