import gql from 'graphql-tag';

export const GetJobTitles = gql`
  query getJobTitles {
    getJobTitles {
      id
      name
      job_category_id
    }
  }
`;

export const GetJobs = gql`
  query getJobs {
    getJobs {
      id
      job_id
      job_title {
        id
        name
      }
      alternate_title
      hirevue_position_id
      active_candidates_count
      created_on
      is_active
    }
  }
`;

export const CreateNewJob = gql`
  mutation createJob($input: JobInput) {
    createJob(input: $input) {
      message
      result {
        id
      }
      success
      error
    }
  }
`;

export const editJob = gql`
  mutation editJob($input: JobEditInput) {
    editJob(input: $input) {
      message
      result {
        id
      }
      success
      error
    }
  }
`;

export const jobActivation = gql`
  mutation jobActivation($input: JobActivation) {
    jobActivation(input: $input)
  }
`;

export const checkIfJobExist = gql`
  query checkIfJobExist($job_title_id: ID, $alternate_title: String) {
    checkIfJobExist(job_title_id: $job_title_id, alternate_title: $alternate_title)
  }
`;

export const getAllJobCategory = gql`
  query getAllJobCategory {
    getAllJobCategory {
      id
      name
      description
    }
  }
`;
