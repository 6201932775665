import React, { CSSProperties, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import Select, {
  MultiValueProps,
  OptionProps,
  PlaceholderProps,
  SingleValueProps,
  ValueContainerProps,
} from 'react-select';
import { NoticeProps } from 'react-select/src/components/Menu';
import classNames from 'classnames';
import _ from 'lodash';

function NoOptionsMessage(props: NoticeProps<OptionType>) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
} as any;

function Control(props: any) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      error={props.selectProps.error}
      helperText={props.selectProps.errorMessage}
      required={props.selectProps.required}
      InputLabelProps={{
        root: props.label,
      }}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          'data-testid': props.selectProps.TextFieldProps.label,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
} as any;

interface OptionType {
  label: string;
  value: string;
}

function Option(props: OptionProps<OptionType>) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      className={props.selectProps.classes.menuItem}
      {...props.innerProps}
      data-testid={props.children}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
} as any;

function Placeholder(props: PlaceholderProps<OptionType>) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

function SingleValue(props: SingleValueProps<OptionType>) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
} as any;

function ValueContainer(props: ValueContainerProps<OptionType>) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
} as any;

function MultiValue(props: MultiValueProps<OptionType>) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      data-testid={props.children + '_Close'}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
} as any;

function Menu(props: any) {
  return (
    <Paper elevation={2} square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

function sortOptions(inputOptions: any) {
  return _.sortBy(inputOptions, (obj) => obj.label.toLowerCase());
}

function TnSelect(props: any) {
  const { classes, variant } = props;
  let options: any = [];
  if (props.supressNone) {
    options = [
      {
        label: 'N/A',
        value: 'N/A',
      },
    ].concat(props.options);
  } else {
    options = sortOptions(props.options);
  }
  const selectStyles = {
    input: (base: CSSProperties) => ({
      ...base,
      '& input': {
        font: 'inherit',
      },
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
  };

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.rootDisabled]: props.disabled,
        [classes.noMinHeight]: props.noMinHeight,
      })}
      // className={
      //   ClassNames({
      //     [classes.root]: true,
      //     [classes.smallInput]: variant === "small",
      //     [classes.largeInput]: variant === 'large',
      //     [classes.mediumInput]: variant === undefined
      //   })
      // }
    >
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          inputId={props.id}
          TextFieldProps={{
            label: props.label,
            InputLabelProps: {
              shrink: true,
              className: classes.label,
            },
            placeholder: props.placeholder,
          }}
          inputProps={{
            'data-testid': props.label,
          }}
          isDisabled={props.disabled}
          options={options}
          components={components}
          value={props.value}
          required={props.required}
          onChange={props.onChange}
          isMulti={props.isMulti}
          autoFocus={props.autoFocus}
          error={props.error}
          errorMessage={props.errorMessage}
        />
      </NoSsr>
    </div>
  );
}

export default withStyles(() => ({
  root: {
    flexGrow: 1,
    minHeight: '76px',
    marginTop: 9,
    marginBottom: 8,
  },
  rootDisabled: {
    opacity: 0.5,
  },
  noMinHeight: {
    minHeight: '0 !important',
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },

  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    paddingBottom: '10px',
    paddingLeft: '12px',
    paddingTop: '10px',
  },
  chip: {
    margin: 0.5,
  },
  noOptionsMessage: {
    padding: 10,
  },
  singleValue: {
    fontSize: 16,
    color: '#1C1E20',
    fontFamily: 'Avenir',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
    paddingBottom: '6px',
    paddingLeft: '12px',
    paddingTop: '10px',
  },
  paper: {
    position: 'absolute',
    zIndex: 1000,
    left: 0,
    right: 0,
    marginTop: 5,
  },
  label: {
    fontSize: '14px !important',
    paddingLeft: '12px',
    paddingTop: '8px',
  },
  menuItem: {
    color: '#1C1E20',
    fontSize: 14,
    '&:hover': {
      backgroundColor: '#e7f5ff !important',
    },
    '&:focus': {
      backgroundColor: '#e7f5ff !important',
    },
  },
}))(TnSelect);
