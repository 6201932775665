import _ from 'lodash';
import React from 'react';
import jwtDecode from 'jwt-decode';

import { GetUserByAuth0IdQuery } from '../../_services/user/queries';
import { GetUserByAuth0Id_user_result } from '../../__generated__/types';
import auth from '../../_services/auth/Auth';
import { client } from '../..';
import { GET_PERMISSION } from '../../_services/client-quieries/clienttypes';

// @ts-ignore
let decodedIdToken: any =
  auth.isTokenValid() && auth.getIdTokenFromLocalStorage() && jwtDecode(auth.getIdTokenFromLocalStorage() as string);
let decodedAccessToken: any =
  auth.isTokenValid() && auth.getIdTokenFromLocalStorage() && jwtDecode(auth.getAccessToken() as string);

let role = '';
if (decodedAccessToken) {
  let scopes = decodedAccessToken.scope.split(' ');
  role = scopes[scopes.length - 1];
}

const CheckPermission = (props: any) => {
  let doesPermissionExists;
  if (decodedIdToken && decodedAccessToken) {
    doesPermissionExists = checkPermission(props.perform);
  }

  return doesPermissionExists
    ? props.yesData
      ? props.yes(props.yesData)
      : props.yes()
    : props.noData
    ? props.no(props.noData)
    : props.no();
};

function checkIfPermissionExists(data: GetUserByAuth0Id_user_result, performAction: string) {
  const flag = data && data.permissions && _.findIndex(data.permissions, { name: performAction }) != -1;

  return flag;
}

CheckPermission.defaultProps = {
  yes: () => null,
  no: () => null,
};

export async function hasPermission(permission: string) {
  return decodedIdToken && (await checkPermission(permission));
}

function checkPermission(performAction: string) {
  try {
    let data = client.readQuery({ query: GET_PERMISSION });

    return checkIfPermissionExists(data, performAction);
  } catch (err) {
    return client
      .query({
        query: GetUserByAuth0IdQuery,
        variables: { auth0_id: decodedIdToken.sub, role: role },
      })
      .then((response: any) => {
        let data = response.data;
        client.writeQuery({
          query: GET_PERMISSION,
          data: {
            permissions: data.user.result.permissions,
          },
        });

        return checkIfPermissionExists(data.user.result, performAction);
      })
      .catch((error) => {
        console.log('write error:', error);

        return Promise.reject(error);
      });
  }
}

export default CheckPermission;
