import gql from 'graphql-tag';

export const GET_LOGGEDIN = gql`
  query GetAuth {
    isLoggedIn @client(always: true)
    loggedInUserAuth0Id @client(always: true)
    loggedInUserFirstname @client(always: true)
    loggedInUserLastname @client(always: true)
    loggedInUserRole @client(always: true)
  }
`;

export const GET_PERMISSION = gql`
  query GetPermission {
    permissions {
      name
    }
  }
`;

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    loggedInUserAuth0Id: String
    loggedInUserRole: String
    loggedInUserFirstname: String
    loggedInUserLastname: String
  }
`;
