import React from 'react';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import { InputProps as StandardInputProps } from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

export interface TnTextFieldProps extends BaseTextFieldProps {
  startAdornment?: any;
  endAdornment?: any;
  errorMessage?: string;
  noOfRows?: number;
  onChange?: (...args: any[]) => any;
  onBlur?: (...args: any[]) => any;
  inputProps?: StandardInputProps['inputProps'];
  disabled?: boolean;
  classes?: any;
  defaultLabel?: boolean;
  characterLimit?: number;
}

class TnTextField extends React.Component<TnTextFieldProps, any> {
  state = {
    focused: false,
  };

  handleChange(value: any) {
    const func = this.props.onChange;
    func && func(value);
  }

  handleBlur() {
    this.props.onBlur && this.props.onBlur();
    this.setState({
      focused: false,
    });
  }

  handleDecimal = (value: string) => {
    if (value.indexOf('.') != -1) {
      if (value.split('.')[1].length <= 2) this.handleChange(value.trim());
      else {
        const floatValue = value.split('.');
        this.handleChange(floatValue[0] + '.' + floatValue[1].substring(0, 2));
      }
    } else this.handleChange(value.trim());
  };

  render() {
    let {
      value,
      label,
      type,
      error,
      errorMessage,
      multiline,
      startAdornment,
      endAdornment,
      rows,
      required,
      onBlur,
      disabled,
      classes,
      defaultLabel,
      characterLimit,
      placeholder,
    } = this.props;
    defaultLabel = defaultLabel ? defaultLabel : false;
    const isFloat = type === 'float';
    const isSignedFloat = type === 'signedFloat';
    if (isFloat || isSignedFloat) {
      type = 'number';
    }

    if (value !== undefined) {
      value = value || '';
      if (characterLimit && (value as string).length > characterLimit) {
        error = true;
      }
    }

    return (
      <React.Fragment>
        <TextField
          value={value}
          fullWidth={true}
          type={type}
          label={label}
          placeholder={placeholder}
          InputLabelProps={{
            shrink: true,
          }}
          disabled={disabled}
          onChange={(event) => {
            const value = event.target.value;

            if (type === 'number' && !(isFloat || isSignedFloat)) {
              const number = parseInt(value);

              if (number && !isNaN(number) && Math.sign(number) !== -1) {
                this.handleChange(number);
              } else {
                this.handleChange('');
              }
            } else if (type === 'number' && (isFloat || isSignedFloat)) {
              const number = parseFloat(value);
              if (Math.sign(number) === -1 && isSignedFloat) {
                this.handleDecimal(value);

                return;
              } else if (Math.sign(number) === -1) {
                return;
              }
              if ((number || number == 0) && !isNaN(number) && Math.sign(number) !== -1) {
                this.handleDecimal(value);
              } else {
                this.handleChange('');
              }
            } else {
              this.handleChange(value);
            }
          }}
          onBlur={() => {
            this.handleBlur();
          }}
          required={required}
          margin="normal"
          variant="filled"
          error={error ? error : false}
          helperText={errorMessage}
          multiline={multiline}
          rows={rows}
          InputProps={{
            startAdornment: startAdornment,
            endAdornment: endAdornment,
            className: classes.textField,
          }}
          inputProps={{
            'data-testid': label,
            maxLength: characterLimit,
            ...this.props.inputProps,
          }}
          onFocus={() => {
            this.setState({
              focused: true,
            });
          }}
        />
        {characterLimit && (
          <div
            className={classNames({
              [classes.characterLimitContainer]: true,
              [classes.empty]: (value as string)?.length === 0 || !this.state.focused,
              [classes.valid]:
                (value as string)?.length > 0 && (value as string)?.length <= characterLimit && this.state.focused,
              [classes.invalid]: (value as string)?.length > characterLimit && this.state.focused,
            })}
          >
            <span>
              {(value as string)?.length ? (value as string).length : 0}/{characterLimit}
            </span>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default withStyles(() => ({
  textField: {
    backgroundColor: 'rgba(33, 33, 33, 0.08)',
  },
  characterLimitContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    fontSize: '12px',
    marginTop: '-8px',
  },
  empty: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  valid: {
    color: '#4471B8',
  },
  invalid: {
    color: '#F44336',
  },
}))(TnTextField);
