import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from '../../_services/auth/Auth';
import { withStyles } from '@material-ui/core/styles';

const PrivateRouteInternal = ({ render, classes, ...rest }) => (
  // to make the contents appear below the fixed navbar
  <div className={classes.base}>
    <div className={classes.route}>
      <Route
        {...rest}
        render={(props) => (auth.isTokenValid() ? render ? render(props) : null : <Redirect to={'/login'} />)}
      />
    </div>
  </div>
);

export const PrivateRoute = withStyles(() => ({
  base: {
    paddingTop: '64px',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    ['@media (max-width:700px)']: {
      // eslint-disable-line no-useless-computed-key
      paddingTop: '124px',
    },
  },
  route: {
    maxWidth: '1920px',
    width: '100%',
  },
}))(PrivateRouteInternal);
