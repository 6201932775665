import gql from 'graphql-tag';

export const GetAllClients = gql`
  query GetAllClients {
    getAllClients {
      id
      name
      url
      logo
    }
  }
`;

export const GetAllHiringCompanies = gql`
  query GetAllHiringCompanies {
    getAllHiringCompanies {
      id
      name
      workspace {
        id
        name
        url
        logo
      }
    }
  }
`;
